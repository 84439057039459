<template>
    <div>
        
        <div class="content content_top_margin" style="min-height: 175px;">
            <div class="content_inner  ">
                <div class="full_width ">
                    <div class="full_width_inner">

                        <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
                            style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-8">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <h1>Working from home<br>
                                                                                The Essentials</h1>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <p>
                                                                                <router-link :to="{ name: 'seating-a45' }">
                                                                                <em>Up
                                                                                        Next: </em> Seating <img
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
                                                                                        alt="" width="64"
                                                                                        height="37">
                                                                                </router-link>
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 52px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1637368762324"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-12">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 52px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>


                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <h2>Claim your space</h2>
                                                        <p>Create a separate and distinct work space in your home, even
                                                            if it is just in the corner of a room. This is essential for
                                                            your concentration, privacy and overall working mindset.
                                                            It’s best to set yourself up at a desk, table or other flat,
                                                            stable surface that can accommodate your computer (laptop),
                                                            monitor and mouse.</p>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>


                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <h2>Know your patterns</h2>
                                                        <p>One of the biggest challenges of working from home is staying
                                                            focused without succumbing to distractions. Therefore,
                                                            knowing your rhythms will go a long way to keeping you on
                                                            your game.</p>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 52px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>


                                                <div
                                                    class="wpb_text_column wpb_content_element  vc_custom_1636501157117">
                                                    <div class="wpb_wrapper">
                                                        <h5><em>A few questions you might want to consider:</em></h5>
                                                        <p>What will your new routine look like? At what time of day are
                                                            you most productive? Early mornings, after a healthy
                                                            breakfast? Mid-afternoons or late at night, once everyone
                                                            else is sound asleep?</p>
                                                        <p>If you participate in video calls, what does your workspace
                                                            look like? Will people see an unmade bed or piles of laundry
                                                            and dirty dishes? Do you prefer background music, white
                                                            noise or complete silence to help you concentrate?</p>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <h2>Continue your work from home journey</h2>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'home-essentials-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Work-From-Home-Home-Essentials-.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Work-From-Home---Home-Essentials-"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'home-essentials-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>The essentials</h4>
                                                                            <p>Create a separate and distinct work space
                                                                                in your home.</p>

                                                                        </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'seating-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="530"
                                                                                        src="../../assets/images/airbnb/Airbnb-Work-From-Home-Seating.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Work-From-Home-Seating"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'seating-a45' }">
                                                                           <div class="wpb_wrapper">
                                                                               <h4>Seating</h4>
                                                                               <p>It’s safe to say that you sit a lot
                                                                                   during your workday.</p>
                                                                           </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'desktop-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Working-From-Home-Desktop.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Working-From-Home---Desktop"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'desktop-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Desktop</h4>
                                                                                <p>Learn where to place your work items for
                                                                                    optimal comfort and productivity.</p>       
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'couches-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Work-From-Home-Couches-Landing-page-Footer-and-Page.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Work-From-Home-Couches---Landing-page,-Footer-and-Page"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'couches-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Couches</h4>
                                                                            <p>While it’s best to work at a desk or
                                                                                table top.</p>

                                                                        </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'household-ergo-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="2560" height="892"
                                                                                        src="../../assets/images/airbnb/REPLACEMENT-Work-From-Home-Household-Ergo2-scaled.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="REPLACEMENT = Work From Home Household Ergo2"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'household-ergo-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Household ergo</h4>
                                                                                <p>Ergonomics at home doesn’t have to be
                                                                                    fancy.</p>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'home-stretches-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Work-From-Home-Home-Stretches.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Work-From-Home---Home-Stretches"
                                                                                       >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'home-stretches-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Home stretches</h4>
                                                                                <p>These stretches to help you feel good
                                                                                    while you work from home.</p>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
          

            </div>
        </div>

    </div>
</template>
<script>
    //import carousel from "vue-owl-carousel"
    export default {
        name: 'home-essentials-a45',
        mounted(){
			document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
		},
    }
</script>